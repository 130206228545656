import {useEffect, useState} from "react";
import Day from './Day'

const Menu = (props) => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if(props.menuData.length === 0){
      return;
    }
    setMenu(props.menuData.days);
  }, [props.menuData]);

  return (
    <div className="container mx-auto md:px-4 text-white md:w-1/2">
        {menu.map((dailymenu, index) => {
          return (<Day day={dailymenu} dayindex={index} key={`day_${index}`}></Day>)
        })}
    </div>
  );
}

export default Menu;
