import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCow} from "@fortawesome/free-solid-svg-icons";

const NoPork = () => {
  return (
    <FontAwesomeIcon icon={faCow}  transform="grow-6" className="mr-1" title="Indeholder laktose"/>
  )
}

export default NoPork;
