import {useEffect, useState} from "react";
import './App.css';

import Menu from './components/Menu'
import moment from 'moment';
import 'moment/locale/da';
import MenuPicker from "./components/MenuPicker";

moment.locale('da');

const App = () => {

  const [menu, setMenu] = useState([]);
  const [version, setVersion] = useState(0);

  const toggleVersion = (newVersion) => {
    setVersion(newVersion)
  }

  useEffect(()=>{
    const versions = ['menu_current.json', 'special_menu_current.json'];
    fetch(versions[version]
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function(jsonMenu) {
          setMenu(jsonMenu)
      });

  },[version])
  return (
    <div className="flex flex-col h-screen bg-[url('/public/graphics/lunch_buffet_big.jpg')] bg-fixed bg-cover">
      <header className="py-1 md:py-5 bg-gray-400/50 text-white border-0 border-b">

        <div className="text-center">
        <span className="title text-5xl font-bold text-white hidden md:block">
            Hvad er der i kantinen?<br/>
          </span>

          <span className="subtitle text-2xl font-bold text-white ">
            Dagens menu - hele uge {menu.weekNumber}
          </span></div>
        <MenuPicker version={version} clickCallbak={toggleVersion}></MenuPicker>
      </header>
      <main className="flex-1 overflow-y-auto p-0 md:p-5">
        <Menu menuData={menu}></Menu>
      </main>

    </div>

  );
}

export default App;
