
import {faCarrot, faDrumstickBite} from "@fortawesome/free-solid-svg-icons";
import Choice from "./Choice";
import {useState} from "react";

const MenuPicker = (props) => {
  const [state, setState] = useState(0);

  const togglePicker = () => {
    const newState = (state * -1) +1;
    setState(newState);

    props.clickCallbak(newState);
  }

  return (<span className="ml-3 bg-green-500/90   switcher relative inline-block rounded-full" onClick={togglePicker}>
    <span className={`selector  bg-blue-700 w-6 z-10  absolute  rounded-full ${state === 1 ? 'right-0' : ''} ttransform transition-all duration-150 ease-out`}>&nbsp;</span>
    <span className={`z-20 relative inline-block pl-1`}>
      <Choice icon={faDrumstickBite} />
      &nbsp;
      <Choice icon={faCarrot} />

    </span>
  </span>);
}
export default MenuPicker;
