import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWheatAwn} from "@fortawesome/free-solid-svg-icons";

const NoPork = () => {
  return (
    <FontAwesomeIcon className="mr-2" icon={faWheatAwn}  transform="grow-6" title="Indeholder gluten"/>
  )
}

export default NoPork;
