import 'moment/locale/da';
import NoMilk from "./icons/NoMilk";
import NoGluten from "./icons/NoGluten";

const Day = (props) => {
    const days = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag'];
    return (
    <div className={`rounded-md mb-2 overflow-hidden border border-gray-800`}>
      <div className={`p-2 panel-heading bg-green-600/90 font-bold  flex flex-row`}>
        <div className="grow">
        {days[props.dayindex]}
        </div>
        <div className={` flex-row gap-3 mr-2 mt-1 'flex' `}>
        {/*    <ThumbsUp titleText={'Det kunne jeg godt lide'}></ThumbsUp>*/}
        {/*    <ThumbsDown titleText={'Det brød jeg mig ikke om'}></ThumbsDown>*/}
        </div>
      </div>
      <div>
        {props.day.courses.map((menu, index) => {
          const allergyRegex =  /\(([L/G]*)\)/;
          const results = allergyRegex.exec(menu);

          let showNoGluten = false;
          let showNoMilk = false;

          if(results != null) {
            showNoMilk = results[1].includes('L')
            showNoGluten = results[1].includes('G')

            menu = menu.replace(allergyRegex, '');
          }

          const menuItems = menu.split(':');
          return (
            <div
              className="py-2
              px-1
              md:px-2
              panel-block
              border-t
              flex
              sm:gap-3
              flex-row
              justify-between
              border-gray
              text-white
              font-medium
              hover:text-white
              hover:bg-gray-600/90
              bg-gray-600/70"
              key={`menuItem_${index}`}>
              <div className=''><strong>{menuItems[0]}:</strong>{menuItems[1]}</div>
              <div className="self-start flex flex-col md:flex-row gap-2 md:gap-2">
                {showNoMilk && <NoMilk/>}
                {showNoGluten && <NoGluten/>}
                </div>
            </div>)
        })}
      </div>
    </div>
  )
}

export default Day;
